import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {BookViewerComponent} from './book-viewer/book-viewer.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'viewer',
    pathMatch: 'full'
  },
  {
    path: 'viewer',
    component: BookViewerComponent
  },
  {
    path: 'viewer/:book/:nr',
    component: BookViewerComponent
  },
  {
    path: 'viewer/:embed/:book/:nr',
    component: BookViewerComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
