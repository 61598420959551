import {Component, ElementRef, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GotteslobService} from "../shared/services/gotteslob.service";
import {IonSelect} from "@ionic/angular";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";



@Component({
  selector: 'app-book-viewer',
  templateUrl: './book-viewer.component.html',
  styleUrls: ['./book-viewer.component.scss'],
})
export class BookViewerComponent implements OnInit {
  embedded = false;
  @ViewChild('select') ionSelect: IonSelect;
  @ViewChild('select2') select: ElementRef;

  @Input()
  set book(bookName: string | undefined) {
    this._book = bookName;
    if (bookName != undefined) {
      this.loadSongNumbers();
    }
  }

  selectedPageNumber: string | undefined = undefined;
  _book: string | undefined;
  songNumbers: string[] = ["1", "22", "43"];
  imagesL: string[] | undefined = undefined;
  images: string[] | undefined = undefined;
  imagesR: string[] | undefined = undefined;

  constructor(private gotteslobService: GotteslobService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location) {

  }

  ngOnInit() {

  }

  private loadSongNumbers() {
    if (this._book != undefined) {
      this.gotteslobService.getNumbers(this._book).subscribe(songNumbers => {
        this.songNumbers = [];
        this.songNumbers = songNumbers;
        if (this.songNumbers != undefined) {

          if(this.router.routerState.snapshot.url.split("/").length > 2) {
            if(this.router.routerState.snapshot.url.split("/")[this.router.routerState.snapshot.url.split("/").length - 3] == 'embed') {
              this.embedded = true;
            }
            var nr = this.router.routerState.snapshot.url.split("/")[this.router.routerState.snapshot.url.split("/").length - 1];
            if (nr != null) {
              this.setSelectedPage(nr);
            }
          }
          if(nr != null) {
            for (let i = 0; i < songNumbers.length; i++) {
              if(songNumbers[i] === nr) {
                this.selectedPageNumber = nr;
              }
            }
          } else {
            this.setSelectedPage(this.songNumbers[0]);
          }
        }
      });
    }
  }

  loadPageNumber() {
    if (this._book != undefined) {
      if (this.selectedPageNumber != null) {
        this.gotteslobService.getPages(this._book, this.selectedPageNumber).subscribe(images => {
          this.images = images;
        });

/*        let previous = this.songNumbers[this.songNumbers.length - 1];
        let next = undefined;

        for (let i = 0; i < this.songNumbers.length; i++) {
          if (this.songNumbers[i] == this.selectedPageNumber) {
            next = this.songNumbers[i % this.songNumbers.length];
          }
          previous = this.songNumbers[i];
        }

        if(next != undefined) {
          this.gotteslobService.getPages(this._book, next).subscribe(images => {
            this.imagesR = images;
          });
        }

 */
      }
    }
  }

  loadPage($event: any) {
    this.setSelectedPage($event?.value);
  }

  setSelectedPage(nr: string) {
    if(this.embedded) {
      this.location.replaceState("/viewer/embed/" + this._book + "/" + nr);
    } else {
      this.location.replaceState("/viewer/" + this._book + "/" + nr);
    }
    this.selectedPageNumber = nr;
    this.loadPageNumber();
    this.select.nativeElement.value = this.selectedPageNumber;
//    this.ionSelect.value = this.selectedPageNumber;
  }

  private getSongNumberID() {
    for (let i = 0; i < this.songNumbers.length; i++) {
      if (this.songNumbers[i] == this.selectedPageNumber) {
        return i;
      }
    }
    return 0;
  }

  previous() {
    var index = this.getSongNumberID();
    console.log((index - 1) % this.songNumbers.length);
    if (index == 0) index = this.songNumbers.length;
    this.setSelectedPage(this.songNumbers[index - 1]);
  }

  next() {
    var index = this.getSongNumberID();
    if (index == this.songNumbers.length - 1) index = -1;
    this.setSelectedPage(this.songNumbers[(index + 1) % (this.songNumbers.length + 1)]);
  }

  firstCapial(param: string | undefined) {
    if (param != undefined) {
      param.charAt(0)?.toUpperCase() + param?.substr(1)?.toLowerCase()
    }
  }

  numberpad() {
    var nr = prompt("Welche NR?");
    this.setSelectedPage(nr);
  }

  tap($event: any) {
    console.log($event);
  }

  @HostListener('window:keydown.arrowLeft', ['$event'])
  onKeyDownLeft(event) {
    this.previous();
  }
  @HostListener('window:keydown.arrowRight', ['$event'])
  onKeyDownRight(event) {
    this.next();
  }

  defaultTouch = { x: 0, y: 0, time: 0 };

  @HostListener('touchstart', ['$event'])
  //@HostListener('touchmove', ['$event'])
  @HostListener('touchend', ['$event'])
  @HostListener('touchcancel', ['$event'])
  handleTouch(event) {
    let touch = event.touches[0] || event.changedTouches[0];

    // check the events
    if (event.type === 'touchstart') {
      this.defaultTouch.x = touch.pageX;
      this.defaultTouch.y = touch.pageY;
      this.defaultTouch.time = event.timeStamp;
    } else if (event.type === 'touchend') {
      let deltaX = touch.pageX - this.defaultTouch.x;
      let deltaY = touch.pageY - this.defaultTouch.y;
      let deltaTime = event.timeStamp - this.defaultTouch.time;

      // simulte a swipe -> less than 500 ms and more than 60 px
      if (deltaTime < 500) {
        // touch movement lasted less than 500 ms
        if (Math.abs(deltaX) > 60) {
          // delta x is at least 60 pixels
          if (deltaX > 0) {
            this.doSwipeRight(event);
          } else {
            this.doSwipeLeft(event);
          }
        }

        if (Math.abs(deltaY) > 60) {
          // delta y is at least 60 pixels
          if (deltaY > 0) {
            this.doSwipeDown(event);
          } else {
            this.doSwipeUp(event);
          }
        }
      }
    }
  }

  doSwipeLeft(event) {
    this.previous();
  }

  doSwipeRight(event) {
    this.next();
  }

  doSwipeUp(event) {
    console.log('swipe up', event);
  }

  doSwipeDown(event) {
    console.log('swipe down', event);
  }
}
