import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GotteslobService {

  constructor(private httpClient: HttpClient) { }

  getBooks(): Observable<string[]> {
    return this.httpClient.get<string[]>('https://gotteslob.ps93.de/gotteslob/?json');
  }

  getNumbers(book: string): Observable<string[]> {
    return this.httpClient.get<string[]>('https://gotteslob.ps93.de/gotteslob/?book=' + book + '&json');
  }

  getPages(book: string, songNumber: string): Observable<string[]> {
    return this.httpClient.get<string[]>('https://gotteslob.ps93.de/gotteslob/?book=' + book + '&nr=' + songNumber + '&json');
  }
}
