import {Component} from '@angular/core';
import {GotteslobService} from "./shared/services/gotteslob.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  books: string[]  = [];

  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  public book: string | undefined = undefined;

  constructor(private gotteslobService: GotteslobService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private location: Location) {


    gotteslobService.getBooks().subscribe(books => {
      this.books = books;
      if(books != undefined) {
        if(this.router.routerState.snapshot.url.split("/").length > 2) {
          var book = this.router.routerState.snapshot.url.split("/")[this.router.routerState.snapshot.url.split("/").length - 2];
          console.log("BOOK");
          console.log(book);
        }

        if(book != null) {
          for (let i = 0; i < books.length; i++) {
            if(books[i] === book) {
              this.book = book;
            }
          }
        } else {
          this.openBook(books[0]);
        }
      }
    });
  }

  firstCapial(param: string | undefined) {
    if(param != undefined) {
      param.charAt(0)?.toUpperCase() + param?.substr(1)?.toLowerCase()
    }
  }
  openBook(book: string) {
    this.book = book;
  }
}
